import React, { useEffect } from "react";
import TopHeader from "./top-header/TopHeader";
import SearchBox from "./search-box/SearchBox";

import "./Header.css";
import Menus from "./menu/Menus";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [isCategory, setIsCategory] = useState(false);

  const fixedHeader = () => {
    if (window.scrollY >= 80) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  window.addEventListener("scroll", fixedHeader);

  const [state, setState] = useState({
    front_top_message: "",
    icon: "",
  });

  const getData = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/adminFrontMessage/id`
    );
    setState({
      front_top_message: res.data.front_top_message,
      icon: res.data.icon,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <header className={`${isSticky ? "sticky active" : "sticky"}`}>
        <TopHeader state={state} />
        <SearchBox val={state} />
        <section className="navbarArea">
          <div className="abaris-nav">
            <div className="container">
              <div className="menuCard">
                <div
                  className={`allCategoryDrop ${isCategory ? "active" : ""}`}
                >
                  <span>categories</span>
                  {isCategory ? (
                    <i
                      className="ri-close-fill"
                      onClick={() => setIsCategory(false)}
                    ></i>
                  ) : (
                    <i
                      className="ri-menu-line"
                      onClick={() => setIsCategory(true)}
                    ></i>
                  )}

                  {isCategory && (
                    <div className="categoryList">
                      <div className="categoryListBody">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="categoryListCard">
                              <h5>Properties</h5>
                              <ul>
                                <li>
                                  <Link to="/products">
                                    For Sale: Houses & Apartments
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    For Rent: Houses & Apartments
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">Lands & Plots</Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    For Rent: Shops & Offices
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    For Sale: Shops & Offices
                                  </Link>
                                </li>

                                <li>
                                  <Link to="/products">PG & Guest Houses</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="categoryListCard">
                              <h5>Jobs</h5>
                              <ul>
                                <li>
                                  <Link to="/products">Jobs</Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Data entry & Back office
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">Sales & Marketing</Link>
                                </li>
                                <li>
                                  <Link to="/products">BPO & Telecaller</Link>
                                </li>
                                <li>
                                  <Link to="/products">Driver</Link>
                                </li>
                                <li>
                                  <Link to={"/products"}>Office Assistant</Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Delivery & Collection
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">Teacher</Link>
                                </li>
                                <li>
                                  <Link to="/products">Cook</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="categoryListCard">
                              <h5>Electronics & Appliances</h5>
                              <ul>
                                <li>
                                  <Link to="/products">TVs, Video - Audio</Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Kitchen & Other Appliances
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Computers & Laptops
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">Cameras & Lenses</Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Games & Entertainment
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">Fridges</Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Computer Accessories
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Hard Disks, Printers & Monitors
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="categoryListCard">
                              <h5>Books, Sports & Hobbies</h5>
                              <ul>
                                <li>
                                  <Link to="/products">Books</Link>
                                </li>
                                <li>
                                  <Link to="/products">Gym & Fitness</Link>
                                </li>
                                <li>
                                  <Link to="/products">
                                    Musical Instruments
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/products">Sports Equipment</Link>
                                </li>
                                <li>
                                  <Link to="/products">Other Hobbies</Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <nav className="navbar navbarItem navbar-expand-md navbar-light">
                  <Menus />
                </nav>
              </div>
            </div>
          </div>
        </section>
      </header>
    </>
  );
}

export default Header;
