import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import ContactAddress from "./contact-address/ContactAddress";
import ContactForm from "./contact-form/ContactForm";
import ContactInfo from "./contact-info/ContactInfo";
import ContactMap from "./contact-map/ContactMap";

function Contact() {
  return (
    <>
      <Breadcrumb title="Contact" />
      <main className="main">
        {/* <ContactMap /> */}

        <div className="contactFormSec p-30">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <ContactInfo />
                <ContactAddress />
              </div>
              <div className="col-lg-6">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Contact;
