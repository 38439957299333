import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import "./Menus.css";
import { useGetMenuListQuery } from "../../../components/products/productSlice";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

function Menus() {
  const isLogin = window.localStorage.getItem("isLogin");
  // const { data, isSuccess } = useGetMenuListQuery()
  const { data: categoryData } = useGetMenuListQuery();

  const [data, setdata] = useState(null);

  const getData = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/category/filter`
    );
    setdata(res.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div
        className="collapse navbar-collapse mean-menu"
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav m-0 p-0">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              Cars
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/products" className="nav-link">
              Motorcycles
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/products" className="nav-link">
              Mobile Phones
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/products" className="nav-link">
              For Sale: Houses & Apartments
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/products" className="nav-link">
            Scooters
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/products" className="nav-link">
            Commercial & Other Vehicles
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/products" className="nav-link">
            For Rent: Houses & Apartments
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <div className="dropdown">
              <button
                className="nav-link"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Brands
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link to={"/"} className="dropdown-item">
                    RK International
                  </Link>
                </li>
                <li>
                  <Link to={"/"} className="dropdown-item">
                    rki66
                  </Link>
                </li>
              </ul>
            </div>
          </li> */}
        

          {/* <div className="dropdown-menu">
              <div className="row">
                <div className="col-lg-3">
                  <div className="menuList">
                    <h4>Products</h4>
                    <ul>
                      <li className="nav-item">
                        <Link to="/products" className="nav-link">
                          <FiChevronRight /> Products
                        </Link>
                      </li>


                      <li className="nav-item">
                        <Link to="/seller" className="nav-link">
                          <FiChevronRight /> Seller
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/privacy-policy" className="nav-link">
                          <FiChevronRight /> Privacy Policy
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/delivery-policy" className="nav-link">
                          <FiChevronRight /> Delivery Policy
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/careers" className="nav-link">
                          <FiChevronRight /> Careers
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="menuList">
                    <h4>Pages</h4>
                    <ul>
                      <li className="nav-item">
                        <Link to="/cart" className="nav-link">
                          <FiChevronRight /> Cart
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/checkout" className="nav-link">
                          <FiChevronRight /> Checkout
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link to="/track-order" className="nav-link">
                          <FiChevronRight /> Track Order
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/sell/sell-with-us" className="nav-link">
                          <FiChevronRight /> Sell With US
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/shop/create" className="nav-link">
                          <FiChevronRight /> Shop Create
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/faq" className="nav-link">
                          <FiChevronRight /> Faq
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/testimonial" className="nav-link">
                          <FiChevronRight /> Testimonial
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="menuList">
                    <ul>
                      <li className="nav-item">
                        <Link to="/terms-of-use" className="nav-link">
                          <FiChevronRight /> Terms and Condition
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/about" className="nav-link">
                          <FiChevronRight /> About
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/contact" className="nav-link">
                          <FiChevronRight /> Contact
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/blog" className="nav-link">
                          <FiChevronRight /> Blog
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/blog-detail" className="nav-link">
                          <FiChevronRight /> Blog Detail
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/photo-gallery" className="nav-link">
                          <FiChevronRight /> Photo Gallery
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/video-gallery" className="nav-link">
                          <FiChevronRight /> Video Gallery
                        </Link>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="menuList">
                    <h4>Login</h4>
                    <ul>
                      <li className="nav-item">
                        <Link to="/login" className="nav-link">
                          <FiChevronRight /> Login
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/registration" className="nav-link">
                          <FiChevronRight /> Registration
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/reset" className="nav-link">
                          <FiChevronRight /> Reset
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}

          {/* {data &&
            data.map((item, i) => {
              return (
                <li className="nav-item perent" key={i}>
                  <Link
                    to={`product/category/${item.title._id}`}
                    className="nav-link"
                  >
                    {item.title.name}
                    <i className="bx bx-chevron-down"></i>
                  </Link>

                  {item.Submenu.length > 0 && (
                    <ul className={`dropdown-menu dropAgro ${i}`}>
                      <div className="row">
                        <div className="col-lg-3 width-100pr">
                          <div
                            className="menuList"
                            style={{ paddingTop: "0px" }}
                          >
                            <ul>
                              {item.Submenu &&
                                item.Submenu.map((item) => {
                                  return (
                                    <li className="nav-item" key={item._id}>
                                      <Link
                                        to={`product/category/${item._id}`}
                                        className="nav-link sub_menu_Text"
                                      >
                                        <FiChevronRight /> {item.name} dd
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  )}
                </li>
              );
            })} */}

          {isLogin === true && (
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Services
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default Menus;
