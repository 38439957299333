import React from "react";

const NewsLetter = () => {
  return (
    <>
      <section className="newsLetter sectionPD">
        <div className="container">
          <div className="newsLetterCard">
            <div className="heading">
              <h4>Sign Up News Letter</h4>
              <p>
                If you are going to use a passage of Lorem Ipsum, you need to be
                sure there isn't anything embarrassing hidden in the middle of
                text.
              </p>
            </div>
            <form className="newsSign">
              <input
                type="mail"
                placeholder="Your email address"
                className="form-control"
                autoComplete="off"
              />
              <button className="btn btn-warning" type="button">
                Sign Up
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsLetter;
