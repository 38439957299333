import React from "react";
import { Link } from "react-router-dom";
import babyBoy from "../../../assets/img/rki/star-img/baby-boy.png";
import babyGirl from "../../../assets/img/rki/star-img/baby-girl.png";
import circle from "../../../assets/img/rki/star-img/circle.png";

function BestSeller() {
  return (
    <>
      <section className="bestSeller sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>Our top best seller</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Suscipit, facere eaque.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="sellerProduct">
                <div className="sellerProductCard">
                  <img
                    src="https://product.webrockmedia.com/toys-wordpress/wp-content/uploads/2022/11/b2.png"
                    alt="Product"
                    className="img-fluid"
                  />
                  <div className="sellerProductText">
                    <h2>our top best seller</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="asideSeller row">
                <div className="col-lg-6">
                  <div className="asideSellerCard">
                    <div className="sellerProductCard">
                      <img src={babyBoy} alt="Product" className="img-fluid" />
                      <div className="sellerProductContent ">
                        <h2>Shop baby boy</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="asideSellerCard">
                    <div className="sellerProductCard">
                      <img src={babyGirl} alt="Product" className="img-fluid" />
                      <div className="sellerProductContent ">
                        <h2 className="text-white">Shop Musical Toys</h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="asideSellerCard">
                    <div className="sellerProductCard ">
                      <img src={circle} alt="Product" className="img-fluid" />
                      <div className="sellerProductContent full ">
                        <h2>We've Got You <span>COVERED</span></h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BestSeller;
