import category1 from "../assets/img/rki/category/1.png";
import category2 from "../assets/img/rki/category/2.png";
import category3 from "../assets/img/rki/category/3.png";
import category4 from "../assets/img/rki/category/4.png";
import category5 from "../assets/img/rki/category/5.png";
import category6 from "../assets/img/rki/category/6.png";
import category7 from "../assets/img/rki/category/7.png";
import category8 from "../assets/img/rki/category/8.png";
export const featuredDB = [
  {
    _id: 1,
    name: "Support chargeur 2 Manette Ps5",
    price: "1,450.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category1,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 2,
    name: "Oppo A17k 6.56″ 3 – 64 Gb Bleu",
    price: "1,150.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category2,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 3,
    name: "Professional Handheld HD DV SLR Digital Camcorder",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category3,    
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 4,
    name: "Pressure cooking Pressure",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category4,    
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 5,
    name: "Asus Pc portable – Asus vivobook e210ma",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category5,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 6,
    name: "Black JBL portable Bluetooth speaker",
    price: "500.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category6,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 7,
    name: "Loudspeaker Home audio",
    price: "1,450.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category7,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 8,
    name: "Aplle MakBook Air 256 GB SSD",
    price: "1,150.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category8,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 9,
    name: "Support chargeur 2 Manette Ps5",
    price: "1,450.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category1,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 10,
    name: "Oppo A17k 6.56″ 3 – 64 Gb Bleu",
    price: "1,150.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category2,
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  },
  {
    _id: 11,
    name: "Professional Handheld HD DV SLR Digital Camcorder",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category3,    
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "Yes",
    unit: "Piece (Piece)"
  },
  {
    _id: 12,
    name: "Pressure cooking Pressure",
    price: "600.00",
    links: ["Wooden Handicrafts", "RK International"],
    url: category4,    
    type: "Standard",
    brand: "RK International",
    category: "Wooden Handicrafts",
    stock: "No",
    unit: "Piece (Piece)"
  }
];
