import React from "react";

function AboutStory() {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">
          <h3 className="subtitle">OUR STORY</h3>
          <p className="lead">
            We are specialized manufacturer and supplier of assortment of Wooden
            Handicrafts of many kinds in different designs, styles and sizes and
            shapes. The handicrafts are highly creative, wonderful and stylish.
            These are manufactured with high class raw materials to enhance
            capacity, durability and beauty of the products. Coustomer`s
            satisfaction is our guarantee.
          </p>
          <p>
            Saharanpur (India) is internationally famous for wood carving.
            Dating back to about 400 years in Mughal period, some craftsmen came
            from Kashmir and settled down in Saharanpur and took this work as to
            earn their bread and butter. Gradually this art got extended amongst
            common man in Saharanpur. And now wooden handicraft work is famed
            all over the world.
          </p>
          <p>
            India abounds in trees which provide the raw material for woodcraft,
            in fact, Indian wood is quite famous worldwide. Teak wood, sheesham,
            deodar, ebony, redwood, rosewood, red cedar, sal and many others are
            the wood from India which are extensively used by Indian craftsmen,
            as they lend themselves to fine decorative carving and inlay work.
          </p>
        </div>
      </div>
    </>
  );
}

export default AboutStory;
