import { Link, useParams } from "react-router-dom";
import AsideBar from "../../components/product-category/AsideBar";
import CatogaryItem from "../../components/product-category/CatogaryItem";

import { useGetCategoriesProductQuery } from "../../components/products/productSlice";
import QuiekViewModal from "../QueikViewModal/QuiekViewModal";

import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

function ProductCategoryPage() {
  const params = useParams();
  // const { data: allData, isLoading } = useGetCategoriesProductQuery(params.id)
  let isError = false;
  const [data, setData] = useState();

  const [modelDataId, setModelDataId] = useState(null);
  const setProduct_id = (id) => {
    setModelDataId(id);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setModelDataId(id);
    setShow(true);
  };

  const getDatas = async () => {
    const res = await axios.get(
      `https://onlineparttimejobs.in/api/product/category/${params.id}`
    );
    setData(res.data);
    console.log(res.data);
  };

  useEffect(() => {
    if (params?.id) {
      getDatas();
    }
  }, [params?.id]);

  const getDataShort = async (e) => {
    try {
      const res = await axios.get(
        ` https://onlineparttimejobs.in/api/product/sort/${e.target.value}`
      );
      setData(res.data);
    } catch (error) {
      alert("Server Error !");
    }
  };

  const [filterState, setFilterState] = useState({
    categories: [],
    brands: [],
    minprice: "",
    maxprice: "",
  });

  const filterdValues = async (id, str) => {
    const clone = { ...filterState };
    if (str === "categories") {
      const arrCat = [...clone.categories];

      let flag = false;
      let index;
      if (!arrCat.length) {
        arrCat.push(id);
        clone.categories = arrCat;
        setFilterState(clone);
      } else {
        for (let i = 0; i <= arrCat.length; i++) {
          if (arrCat[i] === id) {
            flag = true;
            index = i;
          }
        }
        if (index) {
          arrCat.splice(index, 1);
          clone.categories = arrCat;
          setFilterState(clone);
          flag = false;
          index = undefined;
          return;
        }
        if (!flag) {
          arrCat.push(id);
          clone.categories = arrCat;
          setFilterState(clone);
          console.log(clone);
        }
      }
    }
    if (str === "brands") {
      const arrBr = [...clone.brands];

      let flag = false;
      let index;
      if (!arrBr.length) {
        arrBr.push(id);
        clone.brands = arrBr;
        setFilterState(clone);
      } else {
        for (let i = 0; i <= arrBr.length; i++) {
          if (arrBr[i] === id) {
            flag = true;
            index = i;
          }
        }
        if (index) {
          arrBr.splice(index, 1);
          clone.brands = arrBr;
          setFilterState(clone);
          flag = false;
          index = undefined;
          return;
        }
        if (!flag) {
          arrBr.push(id);
          clone.brands = arrBr;
          setFilterState(clone);
        }
      }
    }
    if (str === "size") {
    }
  };
  const [isLoadingVal, setIsloadingVal] = useState(false);
  const getFilterdData = async () => {
    setIsloadingVal(true);
    try {
      const res = await axios.post(
        `https://onlineparttimejobs.in/api/product/filter`,
        filterState
      );
      setData(res.data);
      setIsloadingVal(false);
    } catch (error) {
      alert("Filter Not Apply");
      setIsloadingVal(false);
    }
  };
  return (
    <section className="prodcutsSec">
      <div className="container">
        <div className="row">
          {isLoadingVal && (
            <div className="preloaderCount">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}

          <div className="col-lg-3 col-md-4 col-sm-12">
            <AsideBar
              filterdValues={filterdValues}
              getFilterdData={getFilterdData}
            />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className="row">
              <div className="col-lg-12">
                {isError && <h4>Server Error</h4>}
                {/* {isLoading && <div className="preloaderCount">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>} */}

                <div className="collectionFilter">
                  <div className="totalProducts">
                    <h6>{data?.length} products</h6>
                  </div>
                  <div className="collectionFilterItem">
                    <div className="collectionFilterSort">
                      <select defaultValue="DEFAULT" onChange={getDataShort}>
                        {/* <option value="DEFAULT">Default Sorting</option>
                                            <option value="manual">Featured</option> */}
                        {/* <option value="best-selling">Best selling</option> */}
                        <option value="3">Ascending by Name</option>
                        <option value="4">Descending by Name</option>
                        {/* <option value="price-ascending">Price, low to high</option>
                                            <option value="price-descending">Price, high to low</option> */}
                        <option value="1">Newly added last</option>
                        <option value="2">Newly added first</option>
                      </select>
                    </div>
                    <div className="collectionFilterList">
                      <ul className="no-bullets inline-list text-right">
                        <li>
                          <button
                            type="button"
                            className="grid-view-btn"
                            data-view="Grid"
                            title="Grid"
                          >
                            <i className="ri-layout-grid-fill"></i>
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            className="grid-view-btn"
                            data-view="list"
                            title="List"
                          >
                            <i className="ri-menu-line"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="row changeGrid ">
                  {data &&
                    data?.map((item, i) => {
                      return (
                        <CatogaryItem
                          setProduct_id={setProduct_id}
                          key={item._id}
                          item={item}
                          i={i}
                          handleShow={handleShow}
                        />
                      );
                    })}
                  {data?.length === 0 && <h4>No Products</h4>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modelDataId && (
        <QuiekViewModal
          modelDataId={modelDataId}
          show={show}
          onHide={handleClose}
          size="xl"
          centered
        />
      )}
    </section>
  );
}
export default ProductCategoryPage;
