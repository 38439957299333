import React from "react";
import img1 from "../../../assets/img/rki/network/delivery-truck.png";
import img2 from "../../../assets/img/rki/network/return.png";
import img3 from "../../../assets/img/rki/network/credit-card.png";
import img4 from "../../../assets/img/rki/network/support.png";
function AddingShow() {
  return (
    <>
      <section className="networkSec p-30">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-3">
                  <div className="numberCard">
                    <img alt="Employees Worldwide" src={img1} />
                    <h3>Free Delivery</h3>
                    <h5>For all oders over ₹99</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="numberCard">
                    <img alt="Total Warehouse" src={img2} />
                    <h3>90 Days Return</h3>
                    <h5>If goods have problems</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="numberCard">
                    <img alt="Countries Presence" src={img3} />
                    <h3>Secure Payment</h3>
                    <h5>100% secure payment</h5>
                  </div>
                </div>
                <div className="col-3">
                  <div className="numberCard">
                    <img alt="Processing Plants" src={img4} />
                    <h3>24/7 Support</h3>
                    <h5>Dedicated support</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddingShow;
