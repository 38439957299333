import React, { useState } from "react";
import ChatModal from "./chat-modal/ChatModal";
import userImg from "../../../../../assets/img/rki/chat-seller/profile.png";

const ChatSeller = () => {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="chatSeller">
        <div className="chatSellerHeader">
          <div className="user">
            <div className="userLogo">
              <img
                src={userImg}
                alt="Royal Handicraft"
                className="img-fluid"
              />
            </div>
            <div className="userText">
              <p>Royal Handicraft</p>
            </div>
          </div>
          <div className="arrowSide">
            <i class="ri-arrow-right-s-line"></i>
          </div>
        </div>
        <div className="chatSellerBody">
          <div className="chatBox">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleShow}
            >
              Chat with Seller
            </button>
          </div>
        </div>
        <div className="chatSellerFooter">
          <i className="ri-phone-fill"></i>
          <span className="hide">** *** ****</span>
          <span className="showNumber">Show number</span>
        </div>
      </div>
      <ChatModal data={{show, handleClose}} />
    </>
  );
};

export default ChatSeller;
