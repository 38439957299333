import banner1 from "../assets/img/rki/banner/1.jpg";
import banner2 from "../assets/img/rki/banner/2.png";
export const bannerDB = [
  {
    _id: 1,    
    url: banner1,
  },
  // {
  //   _id: 2,
  //   url: banner2,
  // }

 
];
