import React from "react";
import { FaAddressBook } from "react-icons/fa";
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Ri24HoursFill } from "react-icons/ri";

function ContactAddress() {
  return (
    <>
      <div className="connectSec">
          <div className="row">
            
            <div className="col-sm-6 col-lg-6">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                  <BsFillPhoneFill />
                  <h4>Phone Number</h4>
                  <p>
                    <a href="tel:+91-9808133166">+91-9876543210</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                  <MdEmail />
                  <h4>E-mail Address</h4>
                 
                  <p><a href="mailto:mamastycoons@gmail.com">example@gmail.com</a></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-12">
              <div className="feature-box text-center">
                <div className="feature-box-content">
                  <FaAddressBook />
                  <h4>Address</h4>
                  <p>
                  Namberdar Estate, 2nd Floor, B 98, Kailash Hills, New Friends Colony, New Delhi, Delhi 110025
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default ContactAddress;
