import React, { useEffect } from "react";

import etg from "../../../assets/img/brands/etg.png";
import kynoch from "../../../assets/img/brands/kynoch.png";
import falcon from "../../../assets/img/brands/falcon.png";
import oemff from "../../../assets/img/brands/oemff.png";
import "./Brands.css";
import { Link } from "react-router-dom";
import { useListAllBrandQuery } from "../../products/productSlice";
import axios from "axios";

const imgs = [etg, kynoch, falcon, oemff];

function Brands() {
  const { data, isLoading, error } = useListAllBrandQuery();

  return (
    <>
      <section className="brandsSec">
        <div className="container">
          <div className="brandsItem">
            <div className="brandsHeader">
              <div className="fisherman-content mb-4">
                <h3>top brands</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Suscipit, facere eaque.
                </p>
              </div>
            </div>
            {isLoading && (
              <div style={{ textAlign: "center" }}>
                {" "}
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
            {error && (
              <h5 style={{ textAlign: "center", color: "red" }}>
                Server Error
              </h5>
            )}

            <ul className="d-none">
              {data &&
                data.map((item, i) => {
                  return (
                    <li key={item._id}>
                      <Link to={`/product/brand/${item._id}`}>
                        <img
                          src={imgs[i] ? imgs[i] : "red"}
                          alt="Brand"
                          className="img-fluid"
                        />
                      </Link>
                    </li>
                  );
                })}
            </ul>
            <ul>
              <li>
                <Link to={"/"}>
                  {" "}
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/1.jpg"
                    alt="Brand"
                  />{" "}
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  {" "}
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/2.jpg"
                    alt="Brand"
                  />{" "}
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  {" "}
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/3.jpg"
                    alt="Brand"
                  />{" "}
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  {" "}
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/4.jpg"
                    alt="Brand"
                  />{" "}
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  {" "}
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/5.jpg"
                    alt="Brand"
                  />{" "}
                </Link>
              </li>
              <li>
                <Link to={"/"}>
                  {" "}
                  <img
                    src="https://www.abarissoftech.com/assets/img/portfolio/6.jpg"
                    alt="Brand"
                  />{" "}
                </Link>
              </li>
            </ul>
            <div class="loadMoreButton mt-3">
              <Link to="/view-all-brands" className="btn btn-warning">
                view all brands
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Brands;
