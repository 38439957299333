import React from "react";
import sellImg from "../../../../assets/img/rki/network/shield.png";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const SellModal = ({ data }) => {
  const { sellModal, handleModalClose } = data;
  return (
    <>
      <Modal
        show={sellModal}
        onHide={handleModalClose}
        animation={false}
        dialogClassName="sellModalBox"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="sellModalBody">
            <div className="slideContent">
              <img src={sellImg} alt="Sell" className="img-fluid" />
              <p>Help us become one of the safest places to buy and sell</p>
            </div>
            <div className="loginChoose">
              <div className="loginChooseCard">
              <i className="ri-smartphone-line"></i>
                <p>Continue with Phone</p>  
              </div>
              <div className="loginChooseCard">
                <i className="ri-google-fill"></i>
                <p>Continue with Google</p>
              </div>
            </div>

            <div className="or">
              <span>OR</span>
            </div>
            <div className="loginWithGmail">
              <button type="button" className="btn btn-warning">
                Login with Email
              </button>
            </div>

            <div className="safeWithUs">
              <p>All your personal details are safe with us.</p>
              <p>
                If you continue, you are accepting BRAND{" "}
                <Link to={"/"}>Terms and Conditions</Link> and{" "}
                <Link to={"/"}>Privacy Policy</Link>
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SellModal;
