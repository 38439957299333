import React from "react";
import Modal from "react-bootstrap/Modal";

const OtpModal = ({ data }) => {
  const { handleClose, show, setVerifyShow } = data;
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="chatModalBox">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="otpModalBody">
            <div className="otpModalHeader">
              <h4>Login / Signup</h4>
            </div>
            <form className="otpModalForm">
              <div className="form-group">
                <label htmlFor="phone">Mobile No.</label>
                <input
                  type="number"
                  placeholder="91+ 9876 543 210"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-warning" onClick={()=> setVerifyShow(true)}>
                  get otp
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OtpModal;
