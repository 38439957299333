import React from "react";
import AboutBanner from "./about-banner/AboutBanner";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import AboutStory from "./about-story/AboutStory";
import AboutChoose from "./about-choose/AboutChoose";
import AboutCounters from "./about-counters/AboutCounters";

function About() {
  return (
    <>
    <Breadcrumb title="About" />
      <main className="main about">
        {/* <AboutBanner /> */}
        <AboutStory />
        <AboutChoose />
        <AboutCounters />
      </main>
    </>
  );
}

export default About;
