import React from "react";
import Modal from "react-bootstrap/Modal";
import profile from "../../../../../../assets/img/rki/chat-seller/profile.png";
import arrow from "../../../../../../assets/img/rki/chat-seller/right-arrow.png";
import man from "../../../../../../assets/img/rki/chat-seller/man.png";

const ChatModal = ({ data }) => {
  const { show, handleClose } = data;
  return (
    <>
      <Modal show={show} onHide={handleClose} dialogClassName="chatModalBox">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="chatModalBody">
            <div className="heading">
                <div className="contactUser">
                    <div className="userImg"><img src={profile} alt="Profile" /></div>
                    <div className="userImgLine"><img src={arrow} alt="Arrow" /></div>
                    <div className="userImg"><img src={man} alt="Man" /></div>
                </div>
              <h6>Write a message to Royal Handicraft</h6>
              <form className="chatModalForm">
                <div className="form-group">
                  <input
                    type="text"
                    placeholder="Write your message here"
                    className="form-control"
                  />
                </div>
                <div className="form-group">
                  <button type="button" className="btn btn-warning">
                    send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChatModal;
