import React from "react";
import Modal from "react-bootstrap/Modal";

const VerifyOtp = ({ data }) => {
  const { verifyShow, setVerifyShow } = data;
  return (
    <>
      <Modal
        show={verifyShow}
        onHide={() => setVerifyShow(false)}
        dialogClassName="chatModalBox"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="otpModalBody">
            <div className="otpModalHeader">
              <h4>verify otp</h4>
            </div>
            <div className="mobileNum">
              <p>
                Verify mobile number 9876543210 -{" "}
                <span
                  className="changeText"
                  onClick={() => setVerifyShow(false)}
                >
                  Change
                </span>
              </p>
            </div>
            <form className="otpModalForm">
              <div className="form-group">
                <label htmlFor="otp">Enter OTP</label>
                <input
                  type="number"
                  placeholder="120411"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <button type="button" className="btn btn-warning verify">
                  Verify
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default VerifyOtp;
