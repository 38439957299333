import axios from "axios"
import { useEffect } from "react"
import { useState } from "react"
import { FcApproval, FcCancel } from "react-icons/fc";

function My_product_reviews() {
    const userid = window.localStorage.getItem("user_id");
    const [data, setdata] = useState(null)

    const getData = async () => {
        const res = await axios.get(`https://onlineparttimejobs.in/api/rating/user/${userid}`)
        setdata(res.data)
    }

    useEffect(() => {
        getData()
    }, [])

    return <div className="container">
        <div className="aiz-user-panel">
            <h4>My Product Reviews</h4>
            <div className="card">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Image</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Variant</th>
                            <th scope="col">Rating</th>
                            <th scope="col">Comment</th>
                            <th scope="col">Title</th>
                            <th scope="col">Published</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item, i) => {
                            return <tr key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>
                                    <img style={{width:"100px",height:"100px"}}  src={item?.product_id?.mainimage_url?.url}/>
                                </td>
                                <td>{item?.product_id?.name}</td>
                                <td>{item?.variant?.weight}</td>
                                <td>{item?.rating}</td>
                                <td>{item?.comments}</td>
                                <td>{item?.title}</td>
                                <td style={{fontSize:"22px"}}>{item?.approved ? <FcApproval/> : <FcCancel/>}</td>
                            </tr>
                        })}


                    </tbody>
                </table>
            </div>
        </div>
    </div>
}
export default My_product_reviews