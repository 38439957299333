import React from "react";
import {
  useGetCategoriesQuery,
  useGetMenuListQuery,
} from "../../products/productSlice";

import "./ProductsCategory.css";
import { productData } from "../../../pages/product-detail/mockData";
import { featuredDB } from "../../../rki-data/category";

function ProductsCategory() {
  const { data, isLoading, error } = useGetCategoriesQuery();

  const { data: categoryData } = useGetMenuListQuery();

  return (
    <>
      <section className="productsCategorySec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="fisherman-content">
                <h3>Shop By Category</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do e us mod temp.
                </p>
              </div>
            </div>

            {isLoading && (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {error && (
              <h4 style={{ textAlign: "center", color: "red" }}>
                Server Error
              </h4>
            )}

            {categoryData?.slice(0, 3).map((item, i) => {
              return (
                <div key={item._id} className="col-lg-3 col-md-4 col-sm-12">
                  <div className="productsCategoryItem">
                    
                    <div className="categoryBody">
                      <div className="singleProducts">
                        <a href="/products">
                          {/* <img src={productData.data[1].bigUrl} alt="Product" /> */}
                          <img
                            src={featuredDB[2].url}
                            alt={item?.title?.name}
                          />
                          <p> Ceramic Handicrafts</p>
                          <span className="sale">Sale</span>
                        </a>
                      </div>
                    </div>
                    <div className="categoryHeader">
                      <h5 className="title">{item?.title?.name}</h5>                      
                      {/* <a href="/products">view all </a> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsCategory;
