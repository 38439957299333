import React from "react";

function ContactInfo() {
  return (
    <>
      <div className="contactInfo">
        <h4 className="ls-n-25 m-b-1">Contact Info</h4>
        <p>
          We value your feedback so please do not hesitate to give any feedback
          or suggestions.
        </p>
      </div>
    </>
  );
}

export default ContactInfo;
